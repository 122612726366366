import styled from "styled-components";
import { minBp } from "../../lib/theme";

export const StatusBannerComponent = styled.div`
  ${({ status }) => `
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: #842752;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
  `};
`;

export const Text = styled.p`
    font-family: 'Lato', serif;
    color: white;
  };
       `;
