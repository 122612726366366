﻿import $ from 'jquery'
import Pagination from 'Pagination';
import utils from 'utilities';

//polyfills
import 'es6-promise/auto'; // ES6 Promise Polyfill - needed by fetch
import 'fetch-polyfill';

import 'intl'; // ES6 intl Polyfill
import 'intl/locale-data/jsonp/en.js';

//method for inserting and sorting
import 'array.prototype.insert'

class PlotShowcase extends Pagination {

    urls; //urls for the api
    developmentIds; //development IDs, used as param for api
    numberOfPlots; // total number of plots available under the current filter
    itemsPerPage = 9;

    filteredPlots = []; //list of plots to displayed. A combo of both arrays above with items filters out based on current filters

    //elements
    $cardsContainer; // element which containers all cards
    $noResults; // no results found text
    anchorId; 

    noPlots = false; //indicates whether no plots are found

    constructor(selector) {
        // pass component element, tell element to be tracked on scroll
        // and pass selectors for next arrows, back arrows and paging to extended 'Pagination' class
        super(selector,'o-development-homes', true, '.o-development-homes__arrow--next', '.o-development-homes__arrow--prev', '.o-development-homes__pagination-paging');

        this.init(() => {

            const {
                $element
            } = this;

            this.anchorId = $element.attr('id');

          
            //get the unique development ids from a data attribute
            this.developmentIds = $element.data('development-id').split(',');
            this.showDevelopmentName = this.developmentIds.length > 1;

            //get the api url from a data attribute
            this.urls = [];
            this.developmentIds.forEach(id => this.urls.push($element.data('api').replace("{id}", id)));

            //cache the container element for the cards
            this.$cardsContainer = $('.o-development-homes__cards', $element);

            //cache no results fdound element
            this.$noResults = $('.o-development-homes__none-found', $element);

            //fetch the plot data;
            this.fetchJSON();

        });

    }

    fetchJSON(params = '') {

        let allResults = {
            plots: []
        };

        let promises = this.urls.map(async url => {
            //build up the request to the api - append development id to the api url, along with any other parameters
            const apiAddress = `${url + params}`;
            
            //make the request
            return fetch(apiAddress)
                .then((response) => {
                    //validate respnse, throw error if response not okay
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }      

                    //return json from request if able to proceed
                    return response.json();
                })
                .then((json) => {
                    json.plots.forEach(plot => plot.DevelopmentName = json.Development.DevelopmentName);
                    allResults.plots = allResults.plots.concat(json.plots);
                });
        });
        
        //wait for all API calls to finish. If one of them fails, the exception error is logged.
        Promise.all(promises)
            .then(() => this.processData(allResults))
            .catch((exception) => console.error(exception));
    }

    processData(json) {
        const {
            plots
        } = json;

        //if there are no homes for this development, hide this module and bail
        if(!plots || !plots.length) {
            this.noPlots = true;
            return this.$element.addClass('o-development-homes--hidden');
        }

        //group plots into 'available' and 'featured' in separate arrays
        let {
            featuredPlots,
            availablePlots,
            comingSoonPlots
        } = this.groupPlots(plots);

        this.filteredPlots = [...featuredPlots, ...availablePlots, ...comingSoonPlots];

        //update number of plots found
        this.numberOfPlots = this.filteredPlots.length;

        if (this.numberOfPlots == 0) {
            this.showNoResults();
        }

        if (this.numberOfPlots > 0){
            $('.o-development-homes.grid-container#bh-homes').css(
              'display',
              'block',
            );
        }
        
        // calculate number of pages, used by extended 'Pagination' class
        this.numberOfPages = Math.ceil(this.numberOfPlots / this.itemsPerPage);

        // create pagination
        this.createPagination();

        // generate the items or the first page
        this.loadPage(); 
    }

    groupPlots(plots) {

        const availableRegex = /available/i, //case insensitive regex to test for if plot is 'available';
            comingSoonRegex = /coming soon/i; //case insensitive regex to test for if plot is 'coming soon';
        let featuredPlots = [],
            availablePlots = [],
            comingSoonPlots = [];

        const sortByPrice = (current, insert) => current.PlotPrice < insert.PlotPrice;
        const sortByBedrooms = (current, insert) => current.PlotBedrooms < insert.PlotBedrooms; 

        //loop through all plots            
        plots.forEach((plot) => {

            //destructure to find 'PlotStatus' prop and call it 'status' here
            const {
                PlotStatus: status
            } = plot.PlotStatus;

            // This filters out the plots with no image as that throws an exception and does not render them
            // Not really an issue with PRD, just on the other environments
            if (plot.PlotResizedImage === null) {
                return;
            }

            //if a plot has been markd as features, add to appropriate array ordereing by price
            if(plot.Featured){
               return featuredPlots.insert(plot, sortByPrice);
            }

            //if status is 'available', add to appropriate array ordereing by price
            if (availableRegex.test(status)) {
                return availablePlots.insert(plot, sortByPrice);
            }
            //if status is 'coming soon',  add to appropriate array ordereing by bedrooms
            if (comingSoonRegex.test(status))
                return comingSoonPlots.insert(plot, sortByBedrooms);

        });

        return {
            featuredPlots,
            availablePlots,
            comingSoonPlots
        }

    }

    loadPage(){

        const { itemsPerPage, filteredPlots } = this;

        //calculate the range of indices required fir our current page
        const fromIndex = this.currentPageIndex * itemsPerPage;        
        let toIndex = fromIndex + itemsPerPage;

        //if we exceed the end of the array, set the index to the last element
        if(!filteredPlots[toIndex]) {
            toIndex = filteredPlots.length;
        }

        // take a copy of date for the plots we require for the current page from filtered plots
        const plotsToShow = filteredPlots.slice(fromIndex, toIndex);

        //generate the markup for the page using the plot data
        const cardsMarkup = this.createCards(plotsToShow);

        const prevScrollTop = this.$window.scrollTop();
        const win = this.$window.get(0);
        
        //height get the height of the page before the update
        const oldHeight = Math.max(document.documentElement.scrollHeight, document.documentElement.offsetHeight);

        //inject the markup for the carrent page
        this.$cardsContainer.html(cardsMarkup);

        //get the height of the page after the udpate
        const newHeight = Math.max(document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        //get the components' vertical position
        const elementPosition = this.$element.offset().top;

        //if we are dislaying the desktop layout
        if(utils.device.isLarge()) {
            
            const reattach = this.detachWindowEvents();

            //if the componnent is well in view or we've scroll passed it
            if(prevScrollTop > elementPosition){
                //if the height of the page was larger before it updated - the component has shrunk
                if(oldHeight > newHeight){           

                    //calculate the new scroll position - subract the difference between the height from the scroll position
                    const newScrollPosition = prevScrollTop - (oldHeight - newHeight); 
                    
                    //if the new position is not further up the page than the
                    // top of the component (-80 to account for header)
                    if(newScrollPosition > elementPosition - 80) {
                        //reposition at the to the new position
                        win.scrollTo(0, newScrollPosition)
                    }
                    else {
                        //otherwise reposition at the top of the component
                        win.scrollTo(0, elementPosition - 80);
                    }

                }
                else {
                    //otherwise component as grown -
                    //reposition by adding the the different in height to the scroll position
                    win.scrollTo(0, prevScrollTop + (newHeight - oldHeight));
                }
    
            }
            
            reattach();

        }
    }

    //loop through the plots a build up a string containing the mark up for all the cards
    createCards(plots = this.filteredPlots) {
        return plots.reduce((string, plot) => {
            let card = ''

            try {
                card = this.createCard(plot)
            } catch (error) {
                console.error(error);
            }

            return string + card
        }, '');
    }

    //create an individual card
    createCard(plot) {
        //destructure the values we need from the plot data
        const {
            PlotResizedImage: {
                Xlarge: xLargePlotImage,
                Large: largePlotImage,
                Medium: mediumPlotImage,
                Small: smallPlotImage,
                Xsmall: xsmallPlotImage
            } = {},
            PlotImageWidth: width,
            PlotImageHeight: height,
            PlotVariant: {
                Name: plotVarient
            } = {}, PlotVariant,
            PlotStatus: {
                PlotStatus: status
            } = {}, PlotStatus,
            PlotPrice: price,
            PlotBedrooms: bedrooms,
            PlotNumber: number,
            Featured: featured,
            Url: url,
            EnableSpecialOffer: specialOffer,
            SpecialOffersText: offerText,
            DevelopmentName: developmentName
        } = plot


        //Mixed content issue for different sized images.  
        const regex = /^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i;
       
        const urlImageXLarge = xLargePlotImage.replace(regex, "");
        const urlImageLarge = largePlotImage.replace(regex, "");
        const urlImageMedium = mediumPlotImage.replace(regex, "");
        const urlImageSmall = smallPlotImage.replace(regex, "");
        const urlImageXSmall = xsmallPlotImage.replace(regex, "");

        

        let imageLabelClass;

        if(featured)
            imageLabelClass = 'image-label--featured-home';
        else if (specialOffer)
            imageLabelClass = 'image-label--special-offer';
        else if(status.toLowerCase() === 'coming soon')
            imageLabelClass = 'image-label--coming-soon';

        //build up the mark up for the card using the plot data
        return `
            <div class="o-development-homes__card${!url ? ` o-development-homes__card--disabled` : ``}">
                <div class="o-development-homes__card__media image-label${imageLabelClass ? ` ${imageLabelClass}` : ``}"
                    ${specialOffer ? `data-offer-text="${offerText}"` : ``}>                        
                    <a href="${url}" title="Download features">
                        <picture>
                            <!--[if IE 9]>
                            <video style='display: none;'>
                                <![endif]--><source media="(min-width: 1200px)" srcset="${urlImageXLarge}" ${this.addDimensions(width, height)}><!--[if IE 9]>
                            </video>
                            <![endif]--><!--[if IE 9]>
                            <video style='display: none;'>
                                <![endif]--><source media="(min-width: 1024px)" srcset="${urlImageLarge}" ${this.addDimensions(width, height)}><!--[if IE 9]>
                            </video>
                            <![endif]--><!--[if IE 9]>
                            <video style='display: none;'>
                                <![endif]--><source media="(min-width: 769px)" srcset="${urlImageMedium}" ${this.addDimensions(width, height)}><!--[if IE 9]>
                            </video>
                            <![endif]--><!--[if IE 9]>
                            <video style='display: none;'>
                                <![endif]--><source media="(min-width: 550px)" srcset="${urlImageSmall}" ${this.addDimensions(width, height)}><!--[if IE 9]>
                            </video>
                            <![endif]--><img loading="lazy" srcset="${urlImageXSmall}" ${this.addDimensions(width, height)} alt="Plot ${number}">
                        </picture>
                    </a>
                </div>
                <h3 class="o-development-homes__card__header">${plotVarient}</h3>
                <div class="o-development-homes__card__divider"></div>
                <p class="o-development-homes__card__body">
                    ${developmentName}
                    <br>
                    Plot ${number}
                    <br>
                    ${bedrooms} bedrooms, ${price ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(price).replace(/\.\d+/, '') : ``}
                </p>
                <a href="${url}" class="c-button" title="Download features" tabindex="0">
                    <span>View details</span>
                </a>
            </div>
        `
    }

    addDimensions(width, height) {
        const widthAttr = width > 0 ? `width=${width}` : "";
        const heightAttr = height > 0 ? `height=${height}` : "";
        return `${widthAttr} ${heightAttr}`;
    }

    updatePagination(plots = this.filteredPlots) {
        //update the number of plots found
        this.numberOfPlots = plots.length;

        // calculate number of pages, used by extended 'Pagination' class
        this.numberOfPages = Math.ceil(this.numberOfPlots / this.itemsPerPage);

        // create pagination
        this.createPagination();

        // generate the items or the first page
        // this.loadPage();

        //force pagination to return to page 0
        this.goToPage(0, true);
    }

    showNoResults() {
        // add class  to element which hides pagination arrows
        this.$element.addClass('o-development-homes--empty');

        // show no results found text
        this.$noResults.addClass('o-development-homes__none-found--visible');
    }
}

export default PlotShowcase;
