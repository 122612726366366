﻿import $ from 'jquery';
import Component from 'Component';
import App from 'App';
import utils from 'utilities';
import { throttle, debounce } from 'throttle-debounce';

const { device } = utils;

class SecondaryNav extends Component {

    updateHeaderHeight() {
        const stickyHeader = document.querySelector('.c-secondary-nav');
        this.headerHeight = stickyHeader ? stickyHeader.offsetHeight : 0;
    }

    updateScrollMargin() {
        const extraPadding = 0;
        const stickyHeaderHeight = this.headerHeight;

        document.querySelectorAll('[id]').forEach(target => {
            target.style.scrollMarginTop = `${stickyHeaderHeight + extraPadding}px`;
        });
    }

    bindResizeEvent() {
        $(window).on('resize', debounce(200, () => {
            this.updateHeaderHeight();
            this.updateScrollMargin();
        }));
    }  

    $menuBtn;
    menuOpen = false;
    initalOffset;
    initalState = true;
    scrollDirection;
    prevScrollPosition = 0;
    distanceScrolled = 0;
    $placeholder;
    $links;
    state;
    $activeLink;

    constructor(element) {
        super(element);

        this.headerHeight = 0;

        this.gtmData = {};

        this.init(() => {
            this.updateHeaderHeight();
            this.updateScrollMargin();
            this.bindResizeEvent();
            const { $element } = this;        
            
            this.$menuBtn = $(".c-secondary-nav__menu-button", $element);
            this.$links = $('.c-secondary-nav__links-container .c-secondary-nav__anchor-link', $element);
            this.$homesContainer = $('#bh-homes .o-development-homes__cards');
            
            this.$ctas = $('.property-cta', $element);

            this.$links.on('click', this.handleLinkClick.bind(this));
            this.$ctas.on('click', this.handleCtaClick.bind(this));
            
            const { $menuBtn } = this;

            let div = document.createElement("div");

            div.innerHTML = document.getElementById('developmentInfo')?.innerText || '{}';
            this.gtmData = JSON.parse(div.textContent || div.innerText);
    
            this.setUpDeviceHandlers('setUpSmall setUpMediumSmall setUpMediumDesktop setUpMediumTouch', () => {
                    $menuBtn.on('click', this.toggleMenu.bind(this));
    
                    this.$window.on('scroll', throttle(200, this.handleScroll.bind(this)));
    
                    $(() => this.calculateInitialOffset());
            
                })
                .setUpDeviceHandlers('setUpLargeTouch setUpLargeDesktop', () => {
                    this.$window.on('scroll', throttle(200, this.handleScroll.bind(this)));
                    
                    $(() => this.calculateInitialOffset());
                    
                })
                .bindDeviceHandler()
                .onBeforeSizeUpdate(() => {
                    $menuBtn.off('click');
                    this.$window.off('scroll', this.handleScroll.bind(this));
                });
        });

    }

    handleLinkClick(e) {
        e.preventDefault();
    
        const anchorTargetId = e.delegateTarget.getAttribute('href');
        const anchorTarget = document.querySelector(anchorTargetId);
        
        if (anchorTarget) {
            anchorTarget.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    
        this.createGTM('navPropNav');
        this.closeMenu();
    }

    handleCtaClick(e) {
      this.createGTM('propNavCTA')
    }

    createGTM(type) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: type,
          developmentName: this.gtmData.name,
          developmentStatus: this.gtmData.status,
          region: this.gtmData.region,
          geoLocation: this.gtmData.county,
          location: this.gtmData.town,
        });
      }
    }

    toggleMenu() {     

        this.menuOpen ? this.closeMenu() : this.openMenu();

        return this;
    }

    openMenu() {
        this.$element.addClass("c-secondary-nav--open");

        this.menuOpen = true;

        return this;
    }

    closeMenu() {        
        this.$element.removeClass("c-secondary-nav--open");

        this.menuOpen = false;

        return this;
    }

    handleScroll(e) {

        const scrollPosition = this.$window.scrollTop(),
              { prevScrollPosition, scrollDirection } = this;    

        // if the scroll position is greater than previous scroll position, we are scrolling down
        if(scrollPosition > prevScrollPosition){
            //if we were already scrolling down, update the distance scrolled
            if(scrollDirection === 'down') {
                this.distanceScrolled += scrollPosition - prevScrollPosition;
            }
            //otherwise, update the direction and reset the distance
            else {
                this.scrollDirection = 'down';
                this.distanceScrolled = 0;
            }    
        }
        // otherwise, we are scrolling up
        else if(scrollPosition < prevScrollPosition) {
           //if we were already scrolling up, update the distance scrolled
            if(scrollDirection === 'up') {
                this.distanceScrolled += prevScrollPosition - scrollPosition;
            }
            //otherwise, update the direction and reset the distance
            else {
                this.scrollDirection = 'up';
                this.distanceScrolled = 0;
            } 

        }

        this.prevScrollPosition = scrollPosition;

        if(this.withinHeaderArea() && this.scrollDirection === 'down') {
            this.applyScrollingState();
        } 
        else if (!this.withinHeaderArea()&& this.scrollDirection === 'up') {
            this.applyInitialState();
            
            !this.withinHeaderArea() && App.setSidebarState('primary');
        }

    }

    withinHeaderArea(){        
        return this.$window.scrollTop() >= this.initalOffset - App.getHeaderHeight()
    }

    applyScrollingState(){
        if(this.initalState) {
            this.setTop(App.getHeaderHeight());
            App.hideHeader()
               .setSidebarState('visible');
            this.setFixed()
                .setTop(0)
                .initalState = false;     
            //add a place holder element in the position of where the nav bar was. stops other elements on the page from moving
            this.addPlaceholderElement();
            //add bottom button for coming soon page
            this.addBottomButton();
        }

        return this;
    }

    addPlaceholderElement(){

        //if we haven't already created the element, create a placeholder element for the secondary nav
        if(!this.$placeholder){
            this.$placeholder = $('<div>');
            this.$placeholder.addClass('o-secondary-nav-placeholder');                    
            this.$placeholder.insertBefore(this.$element);
        }

        //ensure the element is not hidden
        this.$placeholder.removeClass('o-secondary-nav-placeholder--hidden');

    }

    hidePlaceholderElement(){
        //if we have a placeholder element, hide it
        if(this.$placeholder){
            this.$placeholder.addClass('o-secondary-nav-placeholder--hidden');
        }
    }

    applyInitialState() {
        if(!this.initalState) {
            App.showHeader()
            this.removeFixed()
                .setTop()
                .initalState = true;
            this.hidePlaceholderElement();       
            this.removeBottomButton();                  
        }

        return this;
    }
    
    setFixed() {
        this.$element.addClass("c-secondary-nav--fixed");

        return this;
    }

    removeFixed() {
        this.$element.removeClass("c-secondary-nav--fixed");
        
        return this;
    }

    setTop(top = '') {
        this.$element.css('top', top);
        
        return this;
    }

    removeTransition(){
        this.$element.css('transition', 'none');
        return this;
    }

    resetTransition(){
        this.$element.css('transition', '');

        return this;
    }

    calculateInitialOffset() {

        if(this.initalState) {
            this.initalOffset = this.$element.offset().top;
        }
        else {
            this.removeTransition()
                .applyInitialState()
            this.initalOffset = this.$element.offset().top;
            this.applyScrollingState();
                
            setTimeout(() => {
                this.resetTransition();
            })
        }

        return this;
    }

    setState(state) {

        if(this.state === state) return;

        this.updateActiveLink(state);

        this.state = state;

    }

    updateActiveLink(selector) {
        let { $activeLink } = this;

        if($activeLink)
            $activeLink.removeClass('c-secondary-nav__anchor-link--active');
        
        this.$activeLink = this.$links.filter((_, link) => {
            const hasMatchingName = link.getAttribute('data-link-name') === selector;
            const hasDisplayNoneImportant = link.style.cssText.includes('display: none !important');
            return hasMatchingName && !hasDisplayNoneImportant;
        });

        this.$activeLink.addClass('c-secondary-nav__anchor-link--active');

        this.$menuBtn.text(this.$activeLink.text() || this.$links.filter((_, link) => 
            !link.style.cssText.includes('display: none !important')
        ).first().text());

        return this;
    }

    addBottomButton() {
      const button = document.getElementById('secondary-nav__bottom-button');
      if (button) {
        button.classList.add('c-secondary-nav__bottom-button--visible');
      }
    }

    removeBottomButton() {
      const button = document.getElementById('secondary-nav__bottom-button');
      if (button) {
        button.classList.remove('c-secondary-nav__bottom-button--visible');
      }
    }

}

export default SecondaryNav;
