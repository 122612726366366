/* eslint-disable react/prop-types */
import React from "react";
import {
  Wrap,
  Inner,
  Title,
  Location,
  TagLine,
  InfoWrap,
  Distance,
  CTA,
  ImageWrap,
  Image,
  Icon,
  ImageLink,
  TitleLink,
} from "./DevelopmentCard.styles.js";
import SelectedMarker from "../../assets/icons/SelectedMarker.svg";
import StatusBanner from "../status-banner/StatusBanner.jsx";

import ContentLabel from "../content-label/ContentLabel";

const DevelopmentCard = ({
  name,
  location,
  tagline,
  url,
  distance,
  lat,
  lng,
  image,
  selectedHouse,
  showAllHomes,
  status,
  setDevelopmentHover,
  setDevelopmentHoverLocation,
}) => {
  const isOpenStatus = status === "Open";
  let developmentHoverLocation = [lat, lng];

  return (
    <Wrap
      selectedHouse={selectedHouse}
      showAllHomes={showAllHomes}
      isOpenStatus={isOpenStatus}
      onMouseEnter={() => {
        setDevelopmentHover(true);
        setDevelopmentHoverLocation(developmentHoverLocation);
      }}
      onMouseLeave={() => {
        setDevelopmentHover(false);
      }}
    >
      <ImageWrap>
        {image ? (
          <ImageLink href={url}>
            <Image src={image.url} alt={image.alt} />
            {status === "Coming Soon" ? <StatusBanner status={status} /> : null}
          </ImageLink>
        ) : null}
      </ImageWrap>
      <Inner>
        {name ? (
          <TitleLink href={url}>
            <Title>{name}</Title>
          </TitleLink>
        ) : null}
        {location ? <Location>{location}</Location> : null}
        {tagline ? <TagLine>{tagline}</TagLine> : null}
        <InfoWrap>
          {distance ? (
            <Distance>
              <Icon src={SelectedMarker} />
              {distance.toFixed(1)} <ContentLabel name="milesAwayLabel" />
            </Distance>
          ) : null}
          {url ? (
            <CTA
              href={url}
              onClick={() => {
                window.dataLayer.push({
                  event: "ViewDevelopment",
                  developmentName: name,
                });
              }}
            >
              <ContentLabel name="viewDevelopmentLabel" />
            </CTA>
          ) : null}
        </InfoWrap>
      </Inner>
    </Wrap>
  );
};

export default DevelopmentCard;
