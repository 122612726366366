import Component from 'Component';
import SitePlanModal from './Components/site-plan-model';
import SitePlanSVG from './Components/site-plan-svg'
import SitePlanPDF from './Components/site-plan-pdf'
import App from 'App';
import utils from 'utilities';
import $ from 'jquery';

class SitePlan extends Component {
    constructor(selector) {
        super(selector, true);

        this.init(() => {
            this.modal = new SitePlanModal(this);
            this.element = this.$element[0];
            this.properties = [];
            this.initLabels = this.initLabels.bind(this);
            this.fetchJSON = this.fetchJSON.bind(this);
            this.toggleFilterVisibility = this.toggleFilterVisibility.bind(this);
            this.handleFilterChange = this.handleFilterChange.bind(this);
            this.resetFilters = this.resetFilters.bind(this);
            this.initFilters = this.initFilters.bind(this);
            this.scrollToBottomGradient = this.scrollToBottomGradient.bind(this);
            
            const zoomInSelector = '.o-site-plan__map-controls--zoom--in';
            const zoomOutSelector = '.o-site-plan__map-controls--zoom--out';
            const showSvg = this.element.querySelector('.o-site-plan__map-container.SVG')
            const showPdf = this.element.querySelector('.o-site-plan__map-container.PDF')
            if(showSvg) {
                this.SitePlanSVG = new SitePlanSVG('.o-site-plan__map-container', zoomInSelector, zoomOutSelector);
            }
            if(showPdf) {
                this.SitePlanPDF = new SitePlanPDF( '.o-site-plan__map-container', zoomInSelector, zoomOutSelector);
            }
            this.initModal = this.modal.initModal.bind(this);
            this.setupCheckboxTitleClick();
            this.initLabels();
            this.initFilters();
            this.fetchJSON();
            this.element.querySelector('.o-site-plan__filter-control').addEventListener('click', this.toggleFilterVisibility);
            this.modal.initModal();

            this.anchorId = this.element.getAttribute("id");

            this.addEventHandler('scrolledIntoView',
                () => {
                    if (utils.device.isLarge()) {
                        App.setSidebarState('collapsed');
                    } else {
                        App.setSidebarState('secondary');
                    }

                    App.setSecondaryNavState(this.anchorId);
                })
                .bindDeviceHandler();
        });
    }

    attachGradientOverlayEvents() {
        const listContainer = $('.o-site-plan__list-container');
        const gradientOverlay = $('.o-site-plan__gradient-overlay');
        const threshold = 20;

        // Define a function to check and hide the gradient overlay
        const checkAndHideOverlay = () => {
            // Calculate the distance from the bottom
            const distanceFromBottom = listContainer[0].scrollHeight - listContainer.scrollTop() - listContainer.innerHeight();

            // Determine if the gradient should be hidden based on the threshold
            if (distanceFromBottom <= threshold) {
                gradientOverlay.hide();
            } else {
                gradientOverlay.show();
            }
        };

        // Run the check once initially
        checkAndHideOverlay();

        // Attach the scroll event listener
        listContainer.on('scroll', checkAndHideOverlay);

        // If you need to handle window resize as well
        $(window).on('resize', checkAndHideOverlay);
    }

    initLabels() {
        this.showFiltersLabel = this.element.hasAttribute('data-label-showfilters')
            ? this.element.getAttribute('data-label-showfilters')
            : null;
        if (!this.showFiltersLabel) {
            this.showFiltersLabel = 'Show filters';
        }

        this.hideFiltersLabel = this.element.hasAttribute('data-label-hidefilters')
            ? this.element.getAttribute('data-label-hidefilters')
            : null;
        if (!this.hideFiltersLabel) {
            this.hideFiltersLabel = 'Hide filters';
        }

        this.quickViewLabel = this.element.hasAttribute('data-label-quickview')
            ? this.element.getAttribute('data-label-quickview')
            : null;
        if (!this.quickViewLabel) {
            this.quickViewLabel = 'Quick View';
        }

        this.noResultsLabel = this.element.hasAttribute('data-label-noresults')
            ? this.element.getAttribute('data-label-noresults')
            : null;
        if (!this.noResultsLabel) {
            this.noResultsLabel = 'No results';
        }

        this.plotLabel = this.element.hasAttribute('data-label-plot')
            ? this.element.getAttribute('data-label-plot')
            : null;
        if (!this.plotLabel) {
            this.plotLabel = 'Plot';
        }

        this.enquiryCTALabel = this.element.hasAttribute('data-label-enquiry-cta')
            ? this.element.getAttribute('data-label-enquiry-cta')
            : null;
        if (!this.enquiryCTALabel) {
            this.enquiryCTALabel = 'View Plot';
        }

        this.brochureCTALabel = this.element.hasAttribute('data-label-brochure-cta')
            ? this.element.getAttribute('data-label-brochure-cta')
            : null;
        if (!this.brochureCTALabel) {
            this.brochureCTALabel = 'Download a brochure';
        }
    }

    fetchJSON() {
        if (!this.element.hasAttribute('data-api')) {
            console.error('The element does not have a data-api attribute.');
            return false;
        }

        fetch(this.element.getAttribute('data-api'))
            .then(response => this.validateResponse(response))
            .then(data => this.processProperties(data))
            .catch(error => {
                const infoElement = this.element.querySelector('.o-site-plan__list-container--info');
                if (infoElement) {
                    infoElement.textContent = this.noResultsLabel;
                }
                this.handleError(error, 'API');
            });
    }

    validateResponse(response) {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    }

    // Processes the properties data
    processProperties(data) {
        const developmentData = data.Development;
        this.properties = data.plots
            .filter(plot => plot.PlotStatus.PlotStatus !== 'Reserved' && plot.PlotStatus.PlotStatus !== 'Sold' && plot.PlotStatus.PlotStatus !== 'Not Released Yet')
            .map(plot => {
                return {
                    ...plot,
                    Development: developmentData
                };
            });

        if (this.properties.length === 0) {
            const elementsToRemove = this.element.querySelectorAll('[data-results-only]');
            elementsToRemove.forEach((element) => element.remove());

            this.element.querySelector('.o-site-plan__no-results').classList.remove('o-site-plan__no-results-hidden');
            this.element.querySelector('.o-site-plan__cta').classList.add('o-site-plan__cta-hidden');
            this.element.querySelector('.o-site-plan__cta').classList.add('o-site-plan__cta-hidden');
            this.element
              .querySelector('.o-site-plan__map-controls--zoom')
              .classList.add('o-site-plan__map-controls--zoom__no-results');

            
            return;
        }
        
        const elementsToRemove = this.element.querySelectorAll('[data-noresults-only]');
        elementsToRemove.forEach((element) => element.remove());

        const sortedProperties = this.sortProperties(this.properties);
        this.populateTable(sortedProperties);
        this.attachGradientOverlayEvents();
    }

    initFilters() {
        // Store the initial states for the dropdowns
        this.initialFilterStates = {
            maxPrice: this.element.querySelector('#maxprice').selectedIndex,
            minPrice: this.element.querySelector('#minprice').selectedIndex,
            minBedrooms: this.element.querySelector('#minbedrooms').selectedIndex,
            maxBedrooms: this.element.querySelector('#maxbedrooms').selectedIndex,
            plotVariant: Array.from(this.element.querySelectorAll('input[type="checkbox"][name="plotVariant"]'))
                .map(checkbox => checkbox.checked)
        };

        // Bind the event listeners for filter changes
        this.element.querySelectorAll('.o-site-plan__checkbox-item input[type="checkbox"], .o-site-plan__drop-down-filter select').forEach(input => {
            input.addEventListener('change', this.handleFilterChange);
        });

        const resetButton = this.element.querySelector('.o-site-plan__filter-reset');
        if (resetButton) {
            resetButton.addEventListener('click', this.resetFilters);
        }
    }

    getFilterCriteria() {
        // Helper function to parse the select input values 
        const parseSelectValue = value => {
            // If the value is not set, or if it is 'MIN' or 'MAX', return undefined
            if (!value || value.toUpperCase() === 'MIN' || value.toUpperCase() === 'MAX') {
                return undefined; // Return undefined for non-numeric options
            }
            // Otherwise, parse the value as an integer
            return parseInt(value, 10);
        };

        // Object to hold the filter criteria based on the form inputs
        const criteria = {
            maxPrice: parseSelectValue(this.element.querySelector('#maxprice').value),
            minPrice: parseSelectValue(this.element.querySelector('#minprice').value),
            minBedrooms: parseSelectValue(this.element.querySelector('#minbedrooms').value),
            maxBedrooms: parseSelectValue(this.element.querySelector('#maxbedrooms').value),
            plotVariant: []
        };

        // Collect all checked checkboxes for the 'plotVariant' criteria
        this.element.querySelectorAll('input[type="checkbox"][name="plotVariant"]:checked').forEach(checkbox => {
            criteria.plotVariant.push(checkbox.value);
        });

        // Return the assembled filter criteria object
        return criteria;
    }

    filterProperties(criteria) {
        return this.properties.filter(property => {
            const price = property.PlotPrice;
            const bedrooms = property.PlotBedrooms;

            const meetsBedroomCriteria = (criteria.minBedrooms === undefined || bedrooms >= criteria.minBedrooms) &&
                (criteria.maxBedrooms === undefined || bedrooms <= criteria.maxBedrooms);

            return meetsBedroomCriteria &&
                (criteria.maxPrice === undefined || price <= criteria.maxPrice) &&
                (criteria.minPrice === undefined || price >= criteria.minPrice) &&
                (criteria.plotVariant.length === 0 || criteria.plotVariant.includes(property.PlotVariant.Name));
        });
    }

    handleFilterChange() {
        // Utility function to adjust min value based on max value
        const adjustMinMax = (minElementId, maxElementId) => {
            const minElement = this.element.querySelector(minElementId);
            const maxElement = this.element.querySelector(maxElementId);
            const minValue = parseInt(minElement.value, 10);
            const maxValue = parseInt(maxElement.value, 10);

            if (minValue > maxValue) {
                maxElement.value = minElement.value;
                return false;
            }
            return true;
        };

        if (!adjustMinMax('#minbedrooms', '#maxbedrooms')) {
            return;
        }

        if (!adjustMinMax('#minprice', '#maxprice')) {
            return;
        }

        const criteria = this.getFilterCriteria();

        const filteredProperties = this.filterProperties(criteria);

        if (filteredProperties.length === 0) {
            console.log('No properties match the criteria');
        }

        this.populateTable(filteredProperties);
    }

    resetFilters() {
        // Reset the dropdown & checkboxes to their initial state
        this.element.querySelector('#maxprice').selectedIndex = this.initialFilterStates.maxPrice;
        this.element.querySelector('#minprice').selectedIndex = this.initialFilterStates.minPrice;
        this.element.querySelector('#minbedrooms').selectedIndex = this.initialFilterStates.minBedrooms;
        this.element.querySelector('#maxbedrooms').selectedIndex = this.initialFilterStates.maxBedrooms;

        this.element.querySelectorAll('input[type="checkbox"][name="plotVariant"]').forEach((checkbox, index) => {
            checkbox.checked = this.initialFilterStates.plotVariant[index];
        });

        // Trigger the filter change with the initial states
        this.handleFilterChange();
    }

    handleError(error, source) {
        console.error(`Error fetching properties from ${source}:`, error);
    }

    sortProperties(properties) {
        const statusPriority = {
            'Available': 1,
            'Coming Soon': 2
        };

        return properties.sort((a, b) => {
            const priorityA = statusPriority[a.PlotStatus.PlotStatus] || 4;
            const priorityB = statusPriority[b.PlotStatus.PlotStatus] || 4;

            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            }

            return a.PlotNumber - b.PlotNumber;
        });
    }

    populateTable(properties) {
        const gridContainer = this.element.querySelector('.o-site-plan__list-container');
        const gradientOverlay = $('.o-site-plan__gradient-overlay');
        let gridItemsHtml = '';

        // If there are no properties to display, show the 'No results' message
        if (properties.length === 0) {
            gridItemsHtml = `<div class="o-site-plan__list-container--info">${this.noResultsLabel}</div>`;
            gradientOverlay.hide();
        } else {
            gradientOverlay.show();
            // If there are properties, build the HTML as usual
            properties.sort(function(a, b) {
                if (a.PlotPrice === 0) return 1;
                if (b.PlotPrice === 0) return -1;
                return parseFloat(a.PlotPrice) - parseFloat(b.PlotPrice);
            });
            properties.forEach((property) => {
                const statusColor = property.PlotStatus.PlotStatusIndicator;
                const propertyId = property.Id;

                const quickViewLink = property.PlotStatus.PlotStatus !== 'Not Released Yet'
                    ? `<a href="#" class="o-site-plan__list-item c-link" data-property-id="${propertyId}"><span>${this.quickViewLabel}</span></a>`
                    : '';

                const plotPrice = property.PlotPrice === 0
                    ? `&ndash;`
                    : `&pound;${property.PlotPrice.toLocaleString()}`;

                const plotBedrooms = property.ExtraBedroom === true
                    ? `${property.PlotBedrooms}/${property.PlotBedrooms + 1}`
                    : `${property.PlotBedrooms}`;

                gridItemsHtml += `
                <div class="o-site-plan__list-row">
                <div class="o-site-plan__list-item">
                    <div class="o-site-plan__key-item" style="--status-color: ${statusColor};"></div>${property.PlotNumber}
                </div>
                <div class="o-site-plan__list-item">${property.PlotType.PlotTypeName}</div>
                <div class="o-site-plan__list-item">${property.PlotVariant.Name}</div>
                <div class="o-site-plan__list-item">${plotBedrooms}</div>
                <div class="o-site-plan__list-item">${plotPrice}</div>
                ${quickViewLink}
                </div>`;
            });
        }

        gridContainer.innerHTML = gridItemsHtml;
    }

    setupCheckboxTitleClick() {
        $('.o-site-plan__checkbox-title').on('click keydown', function (event) {
            // Check for click event or 'Enter'/'Space' keypress
            if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
                // Prevent the default action to avoid scrolling the page when pressing Space
                event.preventDefault();

                var checkboxWrapper = $(this).closest('.o-site-plan__checkbox-wrapper');

                // Toggle the height and 'open' class on click or 'Enter'/'Space' keypress
                if (checkboxWrapper.hasClass('open')) {
                    checkboxWrapper.removeClass('open').height(''); // Reset the height
                } else {
                    checkboxWrapper.addClass('open').height(193);
                }
            }
        });
    }

    toggleFilterVisibility() {
        const filtersContainer = this.element.querySelector('.o-site-plan__filters');
        const filterControl = this.element.querySelector('.o-site-plan__filter-control');

        if (filtersContainer && filterControl) {
            filtersContainer.classList.toggle('visible');

            const isHidden = !filtersContainer.classList.contains('visible');

            filterControl.classList.toggle('is-toggled');

            const textNodes = Array.from(filterControl.childNodes).filter(node => node.nodeType === Node.TEXT_NODE);
            if (textNodes.length > 0) {
                textNodes[0].textContent = isHidden ? ` ${this.showFiltersLabel}` : ` ${this.hideFiltersLabel}`;
            }
        } else {
            console.error('Filters container or filter control element not found in the DOM.');
        }
    }

    scrollToBottomGradient(containerSelector, elementToHideSelector) {
        const container = this.element.querySelector(containerSelector);
        const elementToHide = this.element.querySelector(elementToHideSelector);

        if (!container || !elementToHide) {
            console.error('Elements not found in the DOM');
            return;
        }

        const isScrollable = () => {
            return container.scrollHeight > container.clientHeight;
        };

        const isScrolledToBottom = () => {
            const scrollBottomThreshold = 5;
            return container.scrollTop + container.clientHeight >= container.scrollHeight - scrollBottomThreshold;
        };

        const updateOverlayVisibility = () => {
            if (isScrollable() && !isScrolledToBottom()) {
                elementToHide.style.display = 'block';
            } else {
                elementToHide.style.display = 'none';
            }
        };

        // Mutation observer to monitor changes in the container / for filters changes etc
        const observer = new MutationObserver(() => {
            updateOverlayVisibility();
        });

        observer.observe(container, {
            childList: true,
            subtree: true,
        });

        container.addEventListener('scroll', updateOverlayVisibility);

        updateOverlayVisibility();
    }
}

export default SitePlan;